<template>
  <div class="charge-img">
    <img :src="img" alt="" />
  </div>
</template>

<script>
const img = require('@/assets/charge.png');
export default {
  data() {
    return {
      img,
    };
  },
};
</script>

<style lang="less" scoped>
.charge-img {
  img {
    width: 100%;
  }
}
</style>
